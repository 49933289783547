import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import ExperienceMixanalogCTA from "../components/experience-cta"
import ImageMeta from "../components/ImageMeta"

const ProductBrief = ({ heroImage, title, summary, engineId, category }) => (
  <div className={"col-lg-4 mt-15"}>
    <div className="card shadow-sm" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
      <div className={"card-body text-left"}>
        <Link className="color-main link d-inline" to={`/products/${engineId}`}>
          <div>
            {(category || []).map(c => (
              <span key={c} className={"badge"}>{c}</span>
            ))}
          </div>
          <h4 className="mt-20 mb-15 title">{title}</h4>
          <Img fluid={heroImage.localFile.childImageSharp.fluid}/>
        </Link>
        <div
          className={"color-heading f-15 text-adaptive mt-25"}>{!summary ? undefined : documentToReactComponents(summary.json)}</div>
      </div>
    </div>
  </div>
)

const ProductList = ({ products }) => (
  <section className="showcase_2 bg-light page-padding pb-10 text-center">
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-10">
          <h2 className="small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
            Our Products
          </h2>
          <div className="mt-5 mb-10 f-22 color-heading text-adaptive" data-aos-duration="600" data-aos="fade-down"
               data-aos-delay="300">
            Choose one among our analog gear rooms and listen immediately how your track sounds like on top grade
            equipment.
          </div>
        </div>
      </div>
      <div className="row mt-50">
        <div className={"card-group"}>
          {products.nodes.map(p => (
            <ProductBrief key={p.id} {...p} />
          ))}
        </div>
      </div>
    </div>
  </section>
)

const ProductsPage = ({ data: { products } }) => (
  <LayoutPage>
    <SEO title={"Products"} meta={ImageMeta}/>
    <ProductList products={products}/>
    <ExperienceMixanalogCTA/>
  </LayoutPage>
)

export default ProductsPage

export const query = graphql`
  query {
    products: allContentfulProduct(filter: { enabled: { eq: true } }, sort: { order: [ASC], fields: [order] }) {
      nodes {
        id
        title
        enabled
        engineId
        freeTier
        matPerMinute
        category
        summary {
          json
        }
        heroImage {
          localFile {
            childImageSharp {
              fluid(maxHeight: 250) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        guiHeroImage {
          localFile {
            childImageSharp {
              fluid(maxHeight: 250) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
